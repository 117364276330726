import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import Map from "../components/common/Map"
import AboutUsVideo from "../components/cms/AboutUsVideo"
import FormWrapper from "../components/form/FormWrapper"
import InputField from "../components/form/InputField"
import useFormState from "../components/form/hooks/useFormState"

const ContactUs = () => {
  const [form, _setForm, updateForm] = useFormState({
    name: "",
    phone: "",
    email: "",
    message: "",
  })

  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        title="Contact Us For #1 Best Offer To Sell Watch Jewelry Online"
        description="Sell Your Watch Online. Contact us tel. 305-865-0999 or email sell@grayandsons.com. We buy luxury used Watches and Jewelry. We are located In Miami"
        canonical={"/contact-us/"}
      />

      <section className="w-10/12 max-w-[800px] mx-auto leading-loose space-y-5 py-10">
        <h1>Contact Us</h1>
        <p>
          Do you have old
          <Link to="/jewelry"> jewelry </Link>
          or
          <Link to="/fine-watches"> watches </Link> that are just taking up space in your wardrobe
          or jewelry box? At <Link to="/"> SellUsYourJewelry.com </Link>, we will gladly take your
          old pieces off your hands. We accept a wide range of high-end{" "}
          <Link to="/what-is-my-jewelry-worth"> jewelry brands </Link> and{" "}
          <Link to="/what-is-my-watch-worth"> luxury watches</Link>. We make our process extremely
          simple, making it easy and convenient for you to{" "}
          <Link to="/luxury-jewelry-buyer"> sell your jewelry </Link> for a fair price. Your
          feedback is vital for helping us improve our business in any way that we can, so if you
          have questions or concerns, please don’t hesitate to reach out to one of our
          representatives. Give us a call or fill out our{" "}
          <Link to="/what-are-you-looking-to-sell"> online form</Link> to get started today!
        </p>
        <h2>Visit Our Miami Beach Showroom</h2>
        9595 Harding Avenue
        <br />
        Surfside, FL 33154
        <br />
        <i className="text-sm ">(Located across from Bal Harbour Shops)</i>
        <br />
        <b>Hours of Operation:</b>
        <br />
        Monday - Saturday
        <br />
        10:00am to 5:00pm Eastern
        <h2>Call Us By Phone</h2>
        Telephone: (305) 770-6955
        <br />
        Facsimile: (305) 865-9666
        <h2>Send us a Message</h2>
        <FormWrapper className={"bg-white"} name={"EZ Ship Box"} action={"/thank-you/"} form={form}>
          <div className="flex flex-col space-y-4">
            <InputField
              name={"name"}
              label={"Name"}
              form={form}
              onChange={updateForm}
              required={true}
            />
            <InputField
              name={"phone"}
              label={"Phone"}
              form={form}
              onChange={updateForm}
              required={true}
            />
            <InputField
              name={"email"}
              label={"E-Mail"}
              form={form}
              onChange={updateForm}
              required={true}
            />

            <InputField
              type={"textarea"}
              rows={4}
              name={"message"}
              label={"Message"}
              required={true}
              form={form}
              onChange={updateForm}
            />
          </div>
          <button className="bg-accent text-white hover:bg-accentMd duration-150 ease-in-out px-3 py-1 rounded-lg mt-3">
            SUBMIT FORM
          </button>
        </FormWrapper>
      </section>
      <section className="flex w-full flex-col justify-center py-10">
        <hr className="mb-10 w-10/12 mx-auto" />
        <div className="flex w-full flex-col items-center justify-center ">
          <AboutUsVideo className={"max-w-[800px]"} />
          <a href="tel:+13057706955" className="text-3xl font-bold text-accent hover:text-gray-500">
            <h2 className="px-10 text-center text-3xl uppercase">
              <a href={"tel:+13057706955"}>Call Us Now: 305 770 6955</a>
            </h2>
          </a>
        </div>
      </section>
      <Map />
    </Layout>
  )
}

export default ContactUs
