import React from "react"
import PropTypes from "prop-types"
import YouTube from "../common/YouTube"

export const VideoCard = ({title, url, image}) => {
    return <div className="rounded-md group border shadow-lg duration-300 p-5 hover:text-red-700 hover:border-red-500">
        <YouTube src={url} title={title} placeholder={image} className="w-full aspect-video"/>
        <h2 className="h-7 text-lg font-bold uppercase line-clamp-2">{title}</h2>
    </div>
}

const VideoCardGrid = ({videos}) => {
    return videos && videos.length === 1 ? (
        <div className="w-10/12 mx-auto">
            <VideoCard image={videos[0].image} url={videos[0].url} title={videos[0].title}/>
        </div>
    ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {videos.map((arrayElement, index) => {
                return (
                    <VideoCard
                        key={index}
                        image={arrayElement.image}
                        url={arrayElement.url}
                        title={arrayElement.title}
                    />
                )
            })}
        </div>
    )
}


VideoCard.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        url: PropTypes.string,
        gatsbyImageData: PropTypes.object

    })])
}

VideoCard.defaultProps = {
    title: "",
    url: "",
    image: ""
}

VideoCardGrid.propTypes = {
    videos: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
            url: PropTypes.string,
            gatsbyImageData: PropTypes.object

        })])
    }))
}

VideoCardGrid.defaultProps = {
    videos: []
}


export default VideoCardGrid
