import * as React from "react"
import { VideoCard } from "./VideoCardGrid"

const AboutUsVideo = ({ className }) => {
  return (
    <div className={"w-10/12 mx-auto mb-5 " + className}>
      <VideoCard
        image={"https://img.youtube.com/vi/eqmFkSMB7co/maxresdefault.jpg"}
        url={"https://www.youtube.com/embed/eqmFkSMB7co?si=Dw3Mn499jCwKp-k2"}
        title={"About Us | Sell Us Your Jewelry"}
      />
    </div>
  )
}

export default AboutUsVideo
